body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Sora', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  width: unset;
  height: unset;
}


.dark-table .ant-table {
  background-color: #202029;
  color: #e8e8e8;
  border: 1px solid #3F3F5040;
  border-radius: 8px;
}

.dark-table .ant-table-thead > tr > th {
  background-color: #202029;
  color: white;
  border-color: #3F3F5040;
}

.dark-table .ant-table-tbody > tr > td {
  background-color: #202029;
  color: white;
  border-color: #3F3F5040;
}