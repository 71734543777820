.dark-table .ant-table {
    background-color: #202029;
    color: #e8e8e8;
    border: 1px solid #3F3F5040;
    border-radius: 8px;
  }
  
  .dark-table .ant-table-thead > tr > th {
    background-color: #202029;
    color: white;
    border-color: #3F3F5040;
  }
  
  .dark-table .ant-table-tbody > tr > td {
    background-color: #202029;
    color: white;
    border-color: #3F3F5040;
  }